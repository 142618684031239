import Vue from 'vue'
import CustomTable from '../components/table/CustomTable.vue'
import DatePickerModel from '../components/common/DatePickerModel.vue'
import MonthPickerModel from '../components/common/MonthPickerModel.vue'
import SelectMultiple from '../components/common/SelectMultiple.vue'
import SelectPosModel from '../components/common/SelectPosModel.vue'
import InputQrScanModel from '../components/common/InputQrScanModel.vue'
import SelectEmployeeModel from '../components/common/SelectEmployeeModel.vue'
import SelectSuggestBuyer from '../components/common/SelectSuggestBuyer.vue'
import DateTimePicker from '../components/common/DateTimePicker.vue'
import TimePickerDialog from '../components/common/TimePickerDialog.vue'
import DatePickerMultipleDialog from '../components/common/DatePickerMultipleDialog.vue'
import VueViewer from 'v-viewer'
import VueToastify from "vue-toastify";

Vue.component(CustomTable.name, CustomTable)
Vue.component(DatePickerModel.name, DatePickerModel)
Vue.component(MonthPickerModel.name, MonthPickerModel)
Vue.component(SelectMultiple.name, SelectMultiple)
Vue.component(SelectPosModel.name, SelectPosModel)
Vue.component(InputQrScanModel.name, InputQrScanModel)
Vue.component(SelectEmployeeModel.name, SelectEmployeeModel)
Vue.component(SelectSuggestBuyer.name, SelectSuggestBuyer)
Vue.component(DateTimePicker.name, DateTimePicker)
Vue.component(TimePickerDialog.name, TimePickerDialog)
Vue.component(DatePickerMultipleDialog.name, DatePickerMultipleDialog)
Vue.use(VueViewer)
Vue.use(VueToastify)
